.content-search {
    background-color: #effaff;

    .container-xl {
        max-width: 1140px;
    }

    .search-results {
        padding: 20px 0;
    }

    .search-results-number {
        font-size: 12px;
        font-weight: 400 !important;

        .show-all {
            color: #19b4d1;
            cursor: pointer;
        }
    }
    .search-results-padding {
        align-items: center;
    }
    .search-results-sort-box {
        font-size: 12px;
    }

    .content {
        // background-color: #f4fbff;
        padding-top: 5px;
        padding-bottom: 26px;
        min-height: calc(100vh - 477px);

        h3 {
            font-size: 32px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;

            color: #191b37;
            margin-bottom: 27px;
        }

        .content-search-result {
            position: relative;

            .content-FYI {
                width: 319px;
                display: inline-block;
                border-radius: 3px;
                background-color: #ffffff;
                padding: 20px;
                border-left: 4px solid #31b3d1;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                position: absolute;
                top: 0;
                left: 780px;

                .row {
                    margin-bottom: 16px;

                    .col-auto {
                        padding-right: 10px;
                    }

                    .col {
                        padding-left: 0px;

                        h3 {
                            font-size: 18px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;

                            color: #191c37;
                            margin: 0;
                        }
                    }
                }

                p {
                    margin: 0;
                    font-size: 15px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;

                    color: #191c37;
                }
            }
        }

        .button-content {
            text-align: center;

            button {
                font-size: 18px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                text-align: center;
                padding: 10px 30px;
                color: #19b4d1;
                border-radius: 4px;
                background-color: #ffffff;
                border: 1px solid #d1d1d1;

                &.disabled {
                    cursor: not-allowed;
                }
            }

            .btn-top {
                margin-top: 24px;
            }
        }

        .not-found-content {
            max-width: 553px;
            background: #ffffff;
            padding: 31px 36px;
            border-radius: 4px;
            -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

            h3 {
                font-size: 24px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;

                color: #191b37;
            }

            p {
                font-size: 16px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.63;

                color: #47495f;
            }

            .following {
                margin: 0;

                &:last-of-type {
                    margin-bottom: 38px;
                }
            }

            a {
                padding: 11px 0;
                text-align: center;
                display: block;
                border-radius: 4px;
                background-color: #58a946;
                width: 100%;
                font-size: 17px;
                font-weight: 600;
                color: #ffffff;
            }
        }
    }
}

.content-loading {
    width: 150px;
    height: 150px;
    padding: 20px 30px;
    position: absolute;
    left: calc(50% - 75px);
    top: 200px;
    z-index: 2;

    img {
        width: 100%;
    }
}

@media (max-width: 1350px) {
    .content-search {
        .content {
            .content-search-result {
                .content-FYI {
                    position: relative;
                    left: 0;
                    margin-bottom: 57px;
                    max-width: 320px;
                }
            }
        }
    }
}

@media (max-width: 767.92px) {
    .content-search {
        .content {
            .content-search-result {
                .content-FYI {
                    position: relative;
                    left: 0;
                    margin-bottom: 57px;
                    max-width: 320px;
                }
            }
        }
        .search-results-padding {
            align-items: initial;
            margin-bottom: 0;
        }
    }
}

@media (max-width: 676px) {
    .content-search {
        .content {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media (max-width: 786px) {
    .content-search {
        > .row {
            > div {
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    .content-loading {
        top: 100px !important;
    }
}

@media (min-width: 767px) and (max-width: 851px) {
    .content-search {
        .content {
            padding-left: 0;
            padding-right: 0;

            .container {
                padding: 0;

                > h3 {
                    padding-left: 15px;
                }

                .search-result {
                    .left-content {
                        .address {
                            .more-info {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .content-search .content .content-search-result .content-FYI {
        max-width: 100%;
        width: 100%;
    }
}
